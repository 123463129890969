
import { PERMISSIONS } from '../permissions';

export const MODULES_REF = {
  FEEDS: {
    moduleId: PERMISSIONS.MODULES.FEEDS,
    name: "Feeds",
    show: false,
    routeURL: "feeds",
    imagePath: "",
    iconName: "fa fa-comments",
    modulesrc: "styria"
  },
  TIMEREPORT: {
    moduleId: PERMISSIONS.MODULES.TIMEREPORT,
    name: "Time Report",
    show: false,
    routeURL: "time",
    imagePath: "assets/images/Kronos-icon-dark.svg",
    iconName: ""
  },
  USER_DIRECTORY: {
    moduleId: PERMISSIONS.MODULES.USER_DIRECTORY,
    name: "User Directory",
    show: false,
    routeURL: "user",
    imagePath: "",
    iconName: "fa fa-user"
  },
  TRAININGS: {
    moduleId: PERMISSIONS.MODULES.TRAININGS,
    name: "Trainings",
    show: false,
    routeURL: "trainings",
    imagePath: "",
    iconName: "fa fa-graduation-cap"
  },
  EXPENSES: {
    moduleId: PERMISSIONS.MODULES.EXPENSES,
    name: "Expense Reporting",
    show: false,
    routeURL: "expense",
    imagePath: "",
    iconName: "fa fa-usd"
  },
  LINKS: {
    moduleId: PERMISSIONS.MODULES.LINKS,
    name: "Links",
    show: false,
    routeURL: "links",
    imagePath: "",
    iconName: "fa fa-link"
  },
  HOLIDAYS: {
    moduleId: PERMISSIONS.MODULES.HOLIDAYS,
    name: "Holidays",
    show: false,
    routeURL: "holiday",
    imagePath: "",
    iconName: "fa fa-plane"
  },
  AWARDS: {
    moduleId: PERMISSIONS.MODULES.AWARDS,
    name: "Awards",
    show: false,
    routeURL: "awards",
    imagePath: "",
    iconName: "fa fa-trophy"
  },
  ADMINISTRATION: {
    moduleId: PERMISSIONS.MODULES.ADMINISTRATION,
    name: "Administration",
    show: false,
    routeURL: "admin",
    imagePath: "",
    iconName: "fa fa-cog"
  },
  POLICIES: {
    moduleId: PERMISSIONS.MODULES.POLICIES,
    name: "Policies",
    show: false,
    routeURL: "policy",
    imagePath: "",
    iconName: "fa fa-list-alt"
  },
  PROJECTS: {
    moduleId: PERMISSIONS.MODULES.PROJECTS,
    name: "Projects",
    show: false,
    routeURL: "projects",
    imagePath: "",
    iconName: "fa fa-clone"
  },
  FOODREGISTER: {
    moduleId: PERMISSIONS.MODULES.FOODREGISTER,
    name: "Food Register",
    show: false,
    routeURL: "food-register",
    imagePath: "",
    iconName: "fa fa-cutlery"
  },
  AVAILABILITY: {
    moduleId: PERMISSIONS.MODULES.AVAILABILITY,
    name: "People Availability",
    show: false,
    routeURL: "availabilityModule",
    imagePath: "",
    iconName: "fa fa-address-book-o"
  },
  CALENDARS: {
    moduleId: PERMISSIONS.MODULES.CALENDARS,
    name: "Calendar",
    show: false,
    routeURL: "calendar",
    imagePath: "",
    iconName: "fa fa-calendar"
  },
  EVENTS: {
    moduleId: PERMISSIONS.MODULES.EVENTS,
    name: "Events",
    show: false,
    routeURL: "events",
    imagePath: "",
    iconName: "fa fa-calendar-check-o"
  },
  SKILLS: {
    moduleId: PERMISSIONS.MODULES.SKILLS,
    name: "Skills",
    show: false,
    routeURL: "skills",
    imagePath: "",
    iconName: "fa fa-star"
  },
  FORECAST: {
    moduleId: PERMISSIONS.MODULES.FORECAST,
    name: "Forecast",
    show: false,
    routeURL: "forecast",
    imagePath: "",
    iconName: "fa fa-line-chart"
  },
  UTILIZATION: {
    moduleId: PERMISSIONS.MODULES.UTILIZATION,
    name: "Utilization",
    show: false,
    routeURL: "utilization",
    imagePath: "",
    iconName: "fa fa-hourglass-start"
  },
  NEARBY: {
    moduleId: PERMISSIONS.MODULES.NEARBY,
    name: "Nearby",
    show: false,
    routeURL: "location",
    imagePath: "",
    iconName: "fa fa-map-marker"
  },
  IBUSPACE: {
    moduleId: PERMISSIONS.MODULES.IBUSPACE,
    name: "IBU Space",
    show: false,
    routeURL: "ibu-space",
    imagePath: "",
    iconName: "fa fa-university"
  },
  CUSTOMERSPACE: {
    moduleId: PERMISSIONS.MODULES.CUSTOMERSPACE,
    name: "Portfolio",
    show: false,
    routeURL: "customer-space",
    imagePath: "",
    iconName: "fa fa-handshake"
  },
  FINANCE: {
    moduleId: PERMISSIONS.MODULES.FINANCE,
    name: "Finance",
    show: false,
    routeURL: "finance-module",
    imagePath: "",
    iconName: "fas fa-coins"
  },
  KNOWKRONOS: {
    moduleId: PERMISSIONS.MODULES.KNOWKRONOS,
    name: "KnowKronos",
    show: false,
    routeURL: "know-kronos",
    imagePath: ""
  },
  REPORTS: {
    moduleId: PERMISSIONS.MODULES.REPORTS,
    name: "Reports",
    show: false,
    routeURL: "reports",
    iconName: "fas fa-file-alt"
  },

  // MICROLEARNING: {
  //   moduleId: PERMISSIONS.MODULES.MICROLEARNING,
  //   name: "Microlearning",
  //   show: false,
  //   routeURL: "micro-learning",
  //   imagePath: "",
  //   iconName: "fa fa-comments",
  //   modulesrc: "styria",
  // },
  VENDORS: {
    moduleId: PERMISSIONS.MODULES.VENDORS,
    name: "Vendors",
    show: false,
    routeURL: "vendors",
    iconName: "fas fa-store"
  },

  INDUSTRIES: {
    moduleId: PERMISSIONS.MODULES.INDUSTRIES,
    name: "Industries",
    show: false,
    routeURL: "industries-module",
    imagePath: "",
    iconName: "fas fa-cogs"
  },

  SOLUTIONS: {
    moduleId: PERMISSIONS.MODULES.SOLUTIONS,
    name: "Presales Space",
    show: false,
    routeURL: "solutions",
    imagePath: "",
    iconName: "fa fa-lightbulb-o"
  },


  // MICROLEARNING:{
  //   moduleId: PERMISSIONS.MODULES.MICROLEARNING,
  //   name: "Microlearning",
  //   show: false,
  //   routeURL: "micro-learning",
  //   imagePath: "",
  //   iconName: "fa fa-comments",
  //   modulesrc: "styria",
  // },

};

export const MODULES = Object.keys(MODULES_REF).map(key => MODULES_REF[key]);

export const DEFAULT_MODULE = {
  moduleId: -1,
  name: "Default",
  show: true,
  routeURL: null,
  imagePath: "assets/images/Kronos-icon-dark.svg",
  iconName: ""
}

export const STYRIA_MODULES = [
  // {
  //     moduleId: PERMISSIONS.MODULES.FEEDS,
  //     name: "Feeds",
  //     show: false,
  //     routeURL: "feeds",
  //     imagePath: "",
  //     iconName: "fa fa-comments",
  //     modulesrc: "styria",
  // },
]
