import { Injectable } from '@angular/core';
import { DataService } from '../core/interceptor.service';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';

@Injectable()
export class ReportService {

  constructor(private dataService: DataService) { }


  getBusinessUnitReport(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_BUSINESS_UNIT_REPORT, req);
  }

  getProjectReport(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_PROJECT_UNIT_REPORT, req);
  }

  getAdminProjectReport(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ADMIN_PROJECT_REPORT, req);
  }

  getAllYears() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_YEARS_FOR_LOCK, req);
  }

  getAllInvoiceoOnProject(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_PROJECT_INVOICE_REPORTS, req);
  }

  getAccountReport(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ACCOUNT_REPORTS, req);
  }

  getAllUserReportMonth(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_USER_REPORT_MONTH, req);
  }

  getTaskWiseDownloadReports(startDate, endDate) {
    const req = {
      startDate: startDate, endDate: endDate
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_TASK_WISE_DOWNLOAD_REPORTS, req);
  }

  getWeekWiseDownloadReports(startDate, endDate) {
    const req = {
      startDate: startDate, endDate: endDate
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_WEEK_WISE_DOWNLOAD_REPORTS, req);
  }

  getProjectWiseDownloadReports(startDate, endDate) {
    const req = {
      startDate: startDate, endDate: endDate
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_PROJECT_WISE_DOWNLOAD_REPORTS, req);
  }

  getNoOfWorkingDays(date) {
    const req = {
      date: date
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_NO_OF_WORKING_DAYS, req);
  }

  generateAllocationReport(date, noOfDays) {
    const req = {
      date: date, noOfDays: noOfDays
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GENERATE_ALLOCATION_REPORT, req);
  }

  getFeatureReport(reqData) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_FEATURE_REPORT, reqData);
  }

  getFeatureWiseDownloadReports(startDate, endDate) {
    const req = {
      startDate: startDate, endDate: endDate
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_FEATURE_WISE_DOWNLOAD_REPORTS, req);
  }

  getDefaulterListReport(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_DEFAULTER_LIST_REPORT, req);
  }

  getTeamWiseDownloadReports(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_TEAMWISE_DOWNLOAD_REPORTS, req);
  }

  accountWiseReport(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ACCOUNT_DOWNLOAD_REPORTS, req);
  }
  /*enhanced Reports*/
  getEnhancedProjectReport(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ENHANCED_PROJECT_REPORT, req);
  }

  getEnhancedTeamReport(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ENHANCED_TEAM_REPORT, req);
  }

  getEnhancedAccountReport(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ENHANCED_ACCOUNT_REPORT, req);
  }

  getTaskWiseDownloadReportsById(startDate, endDate, userId) {
    const req = {
      startDate: startDate, endDate: endDate, userId: userId
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_TASK_WISE_DOWNLOAD_REPORTS_BY_ID
      , req);
  }

  getWeekWiseDownloadReportsById(startDate, endDate, userId) {
    const req = {
      startDate: startDate, endDate: endDate, userId: userId
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_WEEK_WISE_DOWNLOAD_REPORTS_BY_ID, req);
  }

  getProjectWiseDownloadReportsById(startDate, endDate, userId) {
    const req = {
      startDate: startDate, endDate: endDate, userId: userId
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_PROJECT_WISE_DOWNLOAD_REPORTS_BY_ID, req);
  }

  getFeatureWiseDownloadReportsById(startDate, endDate, userId) {
    const req = {
      startDate: startDate, endDate: endDate, userId: userId
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_FEATURE_WISE_DOWNLOAD_REPORTS_BY_ID, req);
  }

  getTrainingReport(startDate, endDate) {
    const req = {
      startDate,
      endDate
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_TRAINING_REPORT, req)
  }

  getLockOfYear(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_YEAR_LOCK, req);
  }

  updateLock(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_LOCK, req);
  }

  getLeaveGeneralReport(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_LEAVE_GENERAL_REPORT, req);

  }

  getSubscribedReport() {
    return this.dataService.get(KRONOS.BASE + KRONOS.USER + API.GET_SUBSCRIBED_REPORT);
  }

  subscribeReport(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.SUBSCRIBE_REPORT, req);
  }

  deleteSubscription(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_SUBSCRIPTION, req);

  }

  getReportSummary(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_REPORT_SUMMARY, req);
  }

  getAcandoProjectReport(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ACANDO_REPORT, req);
  }

  getCustomizedReport(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_CUSTOMIZED_REPORT, req);
  }

  getWorkLocationReport(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_WORK_LOCATION_REPORT, req);
  }

  getCountryReport(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_COUNTRY_REPORTS, req);
  }

  getFoodReport(startDate, endDate) {
    const req = {
      startDate,
      endDate
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_FOOD_REPORT, req);
  }

  getAwardsReport(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_AWARDS_REPORT, req);
  }
}

