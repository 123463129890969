import { Injectable } from '@angular/core';
import { DataService } from '../core/interceptor.service';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';
import { Subject } from 'rxjs';

@Injectable()
export class ProjectsService {
  updateSolutionFiles(req: { projectId: any; industryFiles: any[]; }) {
    throw new Error('Method not implemented.');
  }

  object = new Subject<any>();
  changeEmitted1$ = this.object.asObservable()
  project: any = {}
  emitChange1(myMessage: any) {
    this.object.next(myMessage);
    this.project = myMessage;
  }
  constructor(private dataService: DataService) { }
  passAllObject(obj) {
    return obj;
  }
  getAllProjectTask(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_PROJECT_TASK, req);
  }
  getAll() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_PROJECTS, req);
  }
  getAllAccountProjects() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_ACCOUNTS_PROJECTS, req);
  }
  getAllAccount() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_ACCOUNTS, req);
  }
  getAllUnit() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_UNITS, req);
  }
  getAllTemplate() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_TEMPLATE, req);
  }
  getAllChannels() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_CHANNEL, req);
  }

  getAllUsersByOrg() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_USERS, req);
  }
  addNewProject(project) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_NEW_PROJECT, project);
  }

  /**
  *Project request - CRUD
  */
  createProjectRequest(projectRequest) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.CREATE_PROJECT_REQUEST, projectRequest);
  }

  // get requested Resource - user
  getProjectRequestByUserId(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_USER_PROJECT_REQUEST, req);
  }

  getProjectRequestsForAdmin(userSessionId) {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ADMIN_PROJECT_REQUEST, req);
  }

  updateProjectRequest(projectRequest) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_PROJECT_REQUEST, projectRequest);
  }

  updateProjectRequestByIBUOps(projectRequest) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_PROJECT_REQUEST_BY_IBU_OPS, projectRequest);
  }

  changeProjectRequestStatus(projectStatus) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.CHANGE_PROJECT_REQUEST_STATUS, projectStatus);
  }

  update(updateproject) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_PROJECT, updateproject);
  }

  /*
* API is used to get project details based on the id
*/

  getProjectDetailsById(id) {
    const req = {
      id: id
    }

    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_PROJECT_DETAILS_BY_ID, req);
  }

  getProjectUpdates(obj) {
    this.project = obj;
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_PROJECT_UPDATES, this.project);
  }

  delete(id) {
    const req = {
      id: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_PROJECT, req);
  }

  addNewProjectUpdate(project) {
    this.project = project;
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_NEW_PROJECT_UPDATE, this.project);
  }

  editProjectUpdate(project) {
    this.project = project;
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_PROJECT_UPDATE, this.project);
  }


  deleteProjectUpdate(id) {
    const req = {
      id: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_PROJECT_UPDATE, req);
  }

  /*
  * returns projects based on project admins
  */
  getAllAdminProjects() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_ADMIN_PROJECTS, req);
  }

  addProjectEvent(projectEventObj) {
    this.project = projectEventObj;
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_UPDATE_PROJECT_EVENT, this.project);
  }
  deleteProjectEvent(eventObjId) {
    const req = {
      id: eventObjId
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_PROJECT_EVENT, req);
  }
  getProjectEventsApi(event, projectId) {
    const req = {
      startDate: event.start, endDate: event.end, projectId: projectId
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_PROJECT_EVENT, req);
  }


  getProjectDailyTimeByMonth(event, id) {
    const req = {
      startDate: event.start, endDate: event.end, id: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_PROJECT_DAILYTIME_BY_MONTH, req);
  }

  GetProjectMemberLeaves(year, projectId) {
    const req = {
      projectId: projectId, month: year
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_PROJECT_MEMBER_LEAVES, req);
  }

  AddMembertoProject(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_PROJECT_MEMBER, req);
  }
  AddMembertoProjectAsAdmin(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_PROJECT_MEMBER_AS_ADMIN, req);
  }
  projectMember(projectId) {
    const req = {
      projectId: projectId
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_PROJECT_MEMBERS, req);
  }
  deleteProjectMember(updateMemberObj, projectId) {
    const req = {

      projectId: projectId, proRole: updateMemberObj.proRole, userId: updateMemberObj.userId
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_PROJECT_MEMBERS, req);
  }
  deleteProjectMemberAsAdmin(updateMemberObj, projectId) {
    const req = {

      projectId: projectId, proRole: updateMemberObj.proRole, userId: updateMemberObj.userId
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_PROJECT_MEMBERS_AS_ADMIN, req);
  }
  updateMemberToProject(obj, projectId) {
    const req = {
      projectId: projectId, proRole: obj.roleId, userId: obj.userId
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_PROJECT_MEMBERS, req);
  }
  updateMemberToProjectAsAdmin(obj, projectId) {
    const req = {
      projectId: projectId, proRole: obj.roleId, userId: obj.userId
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_PROJECT_MEMBERS_AS_ADMIN, req);
  }

  projectInsights(projectInsightsDate) {
    this.project = projectInsightsDate;
    return this.dataService.post(KRONOS.BASE + KRONOS.ANALYTICS + API.TOTAL_TIME_SPENT_PER_PROJECT, this.project);
  }
  projectTaskInsights(projectInsightsDate) {
    this.project = projectInsightsDate;
    return this.dataService.post(KRONOS.BASE + KRONOS.ANALYTICS + API.TOTAL_TIME_SPENT_PER_PROJECT_ID, this.project);
  }

  projectMonthInsights(obj) {
    return this.dataService.post(KRONOS.BASE + KRONOS.ANALYTICS + API.MONTH_WISE_PER_PROJECT_ID, obj);
  }

  projectDayInsights(obj) {
    return this.dataService.post(KRONOS.BASE + KRONOS.ANALYTICS + API.DAY_WISE_PER_PROJECT_ID, obj);
  }
  getUserTaskData(obj) {
    return this.dataService.post(KRONOS.BASE + KRONOS.ANALYTICS + API.GET_TOTALTIME_SPENT_PER_PROJECT_PER_TASK, obj);
  }
  getProjectTags() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.PROJECT_UPDATE + API.GET_TAGS, req)
  }

  searchBasedOnTags(searchRequest) {
    return this.dataService.post(KRONOS.BASE + KRONOS.PROJECT_UPDATE + API.SEARCH_BASED_ON_TAGS, searchRequest);
  }


  /*
   Get all currency
 */
  getAllCurrency() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_CURRENCY, req);
  }

  /*
    Add invoice
  */
  addProjectInvoice(req) {

    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_INVOICE, req);
  }

  /*
    Get all project invoice
  */
  getAllProjectInvoice(id) {
    const req = {
      projectId: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_PROJECT_INVOICES, req);
  }

  /*
    Update project invoice
  */
  updateProjectInvoice(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_INVOICE, req);
  }

  /*
  Delete project invoice
*/
  deleteProjectInvoice(id) {
    const req = {
      id: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_PROJECT_INVOICES, req);
  }

  getProjectTime(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_PROJECT_TIME, req);
  }

  addNewAuditTag(obj) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_PROJECT_INVOICES, obj);
  }

  // Notes Services
  addNewNote(note) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_NEW_NOTES, note);
  }

  getAllNotes(notes) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_NOTES, notes);
  }

  updateNotes(updateNote) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_NOTES, updateNote);
  }

  getParentProjectUpdates() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_PARENT_PROJECT_UPDATES, req);
  }


  /*ProjectHealth*/
  updateProjectHealthCheck(health) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_PROJECT_HEALTH, health);
  }

  deleteNotes(id) {
    const req = {
      id: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_NOTES, req);
  }
  /*add Audit Tags*/
  addAuditTag(obj) {
    return this.dataService.post(KRONOS.BASE + KRONOS.AUDIT + API.ADD_AUDIT_TAGS, obj);
  }
  /*delete Audit Tag*/
  deleteAuditTag(obj) {
    return this.dataService.post(KRONOS.BASE + KRONOS.AUDIT + API.DELETE_AUDIT_TAG, obj);
  }
  /*get Audit Tags*/
  getAllAuditTags() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.AUDIT + API.GET_ALL_AUDIT_TAGS, req);
  }

  // allocation
  addAllocation(obj) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_ALLOCATION, obj);
  }

  getAllocation(obj) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_ALLOCATION, obj);
  }

  getMemberUtilization(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_MEMBER_UTILIZATION, req);
  }
  updateAllocation(obj) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_ALLOCATION, obj);
  }

  deleteAllocation(id) {
    const req = {
      id: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_ALLOCATION, req);
  }

  updateUserBillingApproval(obj) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_USER_BILLING_STATUS, obj);
  }

  /*LatesAuditForOrg */
  getRecentOrgAudits(audit) {
    return this.dataService.post(KRONOS.BASE + KRONOS.AUDIT + API.GET_RECENT_ORG_AUDITS, audit)

  }
  /*Lessons and Risks*/
  getAllLessonsRisks(obj) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_LESSONS, obj)
  }

  addLessonsRisks(obj) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_LESSONS_RISKS, obj)
  }
  delLessonsRisks(obj) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_LESSONS_RISKS, obj)
  }
  updateLessonsRisks(obj) {

    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_LESSONS_RISKS, obj)
  }

  // Comments
  getAllRiskLearningComments(comment) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_COMMENTS, comment);
  }
  addComments(data) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_COMMENTS, data);
  }
  // addRiskComments(data){
  //   data.sessionId = this.utils.getSessionId();
  //   return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_COMMENTS,data);
  // }
  deleteComments(obj) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DEL_COMMENTS, obj);
  }
  userTimeSpent(projectId, date) {
    const req = {
      projectId: projectId,
      date: date
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.PROJECT_USER_TIME_SPENT, req);
  }
  /*Lesson and Risks Tags*/
  addLessonRisksTags() {
    const obj = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_LESSONSRISKS_TAGS, obj)
  }

  /*projectReport*/
  getProjectReport(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_PROJECT_INVOICE_REPORTS_FOR_PROJECT, req);
  }
  /*Project Estimation*/
  newEstimate(data) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_PROJECT_EST, data);
  }
  getEstimations(data) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ESTIMATIONS, data);
  }
  updateEstimate(data) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_ESTIMATIONS, data);
  }
  delEstimate(data) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_ESTIMATIONS, data);
  }
  getProjectInvoiceTime(obj) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_PROJECT_INVOICE_TIME, obj);
  }
  getInsightEstimations(data) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_INSIGHT_ESTIMATIONS, data);
  }

  createAccountsProject(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.CREATE_ACCOUNTS_PROJECT, req);
  }

  updateAccountsProject(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_ACCOUNTS_PROJECT, req);
  }

  deleteAccountsProject(id) {
    const req = {
      id: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_ACCOUNTS_PROJECT, req);
  }

  getAccountProjectDetailsById(id) {
    const req = {
      id: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ACCOUNT_PROJECT_DETAILS, req);
  }

  getAllDomain() {
    return this.dataService.get(KRONOS.BASE + KRONOS.PROJECT + API.GET_ALL_DOMAIN);
  }

  addUpdateDomain(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.PROJECT + API.ADD_UPDATE_DOMAIN, req);
  }

  deleteDomain(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.PROJECT + API.DELETE_DOMAIN, req);
  }

  publicProjectsUpdate(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.PROJECT + API.PUBLIC_PROJECTS_UPDATE, req);
  }

  updateProjectContract(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_PROJECT_CONTRACT, req);
  }

  getAllUserProjects(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_USER_PROJECTS, req);
  }

  addProjectMilestoneBilling(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_PROJECT_MILESTONE_BILLING, req);
  }

  getProjectMilestoneBilling(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_PROJECT_MILESTONE_BILLING, req);
  }

  getMonthlyProjectBilling(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_MONTHLY_PROJECT_BILLING, req);
  }

  updateProjectBillingStatus(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_PROJECT_BILLING_STATUS, req);
  }

  addUserProjectBillingRate(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_USER_PROJECT_BILLING_RATE, req);
  }

  getUserProjectBillingRate(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_USER_PROJECT_BILLING_RATE, req);
  }

  getAllProjectUserDetails(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_PROJECT_USER_DETAILS, req);

  }

  getAllNonPublicProjects() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_NON_PUBLIC_PROJECTS, req);
  }

  getProjectExtendedDetails(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_PROJECT_EXTENDED_DETAILS, req);
  }

  addProjectExtendedDetails(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_UPDATE_PROJECT_EXTENDED_DETAILS, req);
  }

  addRevenueForecastDetails(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_REVENUE_FORECAST_DETAILS, req);
  }

  getAllProjectSkills() {
    const req = {}
    return this.dataService.post(KRONOS.BASE + KRONOS.PROJECT + API.GET_ALL_PROJECT_SKILLS, req);
  }

  getAllSkillsByProjectId(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.PROJECT + API.GET_ALL_SKILLS_BY_PROJECT_ID, req);
  }

  addProjectSkill(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.PROJECT + API.ADD_PROJECT_SKILL, req);
  }
  
  deleteProjectSkill(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.PROJECT + API.DELETE_PROJECT_SKILL, req);
  }
  
  getProjectsByUnitId(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_PROJECTS_BY_UNIT_ID, req);
  }

  getAllProjectListBySolutionId(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_PROJECTS_BY_SOLUTION_ID, req);
  }

  addProjectFile(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_PROJECT_FILE, req);
  }

  getAllProjectFiles(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GEL_ALL_PROJECT_FILES, req);
  }

  updateProjectFiles(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_PROJECT_FILES, req);
  }
}
