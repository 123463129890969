import { Injectable } from '@angular/core';
import { POSTOG_ANALYTICS_CONFIG } from 'app/app-constants';

@Injectable({
  providedIn: 'root',
})
export class PosthogService {
  constructor() {
    this.initializePosthog();
  }

  private initializePosthog(): void {
    (function (document: Document, window: any) {
      let posthog = window.posthog || undefined;
  
      if (!posthog || !posthog.__SV) {
        posthog = window.posthog = {} as any;
        posthog._i = [];
  
        // Define the PostHog initialization function
        posthog.init = function (apiKey: string, config: { [key: string]: any }, libraryName?: string) {
          const stubMethods = [
            "init", "capture", "register", "register_once", "track",
            "identify", "alias", "set_config", "reset", "debug"
          ];
  
          // Create stub functions for each method
          function stubFunction(posthogObject: any, methodName: string) {
            const parts = methodName.split(".");
            if (parts.length === 2) {
              posthogObject = posthogObject[parts[0]];
              methodName = parts[1];
            }
            posthogObject[methodName] = function (...args: any[]) {
              (posthogObject.push || Array.prototype.push).call(posthogObject, [methodName, ...args]);
            };
          }
  
          // Load the PostHog script asynchronously
          const scriptElement = document.createElement("script");
          scriptElement.type = "text/javascript";
          scriptElement.crossOrigin = "anonymous";
          scriptElement.async = true;
          scriptElement.src = config.api_host.replace(".i.posthog.com", "-assets.i.posthog.com") + "/static/array.js";
  
          const firstScript = document.getElementsByTagName("script")[0];
          if (firstScript && firstScript.parentNode) {
            firstScript.parentNode.insertBefore(scriptElement, firstScript);
          }
  
          let libraryObject: any = posthog;
          if (libraryName) {
            libraryObject = posthog[libraryName] = [];
          } else {
            libraryName = "posthog";
            libraryObject = posthog;
          }
  
          // Initialize people tracking methods
          libraryObject.people = libraryObject.people || [];
          libraryObject.toString = function (showStub?: boolean): string {
            let result = "posthog";
            if (libraryName !== "posthog") {
              result += "." + libraryName;
            }
            return showStub ? `${result} (stub)` : result;
          };
          libraryObject.people.toString = function (): string {
            return libraryObject.toString(true) + ".people (stub)";
          };
  
          // Create stub functions for each PostHog method
          for (const method of stubMethods) {
            stubFunction(libraryObject, method);
          }
  
          // Push the initialization settings to PostHog
          posthog._i.push([apiKey, config, libraryName]);
        };
  
        posthog.__SV = 1; // Mark as initialized
      }
  
      // Initialize PostHog with your API key and configuration
      posthog.init(POSTOG_ANALYTICS_CONFIG.apiKey, {
        api_host: POSTOG_ANALYTICS_CONFIG.apiHost,
        person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
      });
    })(document, window);
  }
  
  
  
  

  public trackPageVisit(pageName: string): void {
    const posthog = (window as any).posthog as {
      capture: (eventName: string, properties?: Record<string, any>) => void;
    };
  
    if (posthog) {
      posthog.capture('Page Visit', { page: pageName });
    } else {
      console.warn('PostHog is not initialized.');
    }
  }
  
}
