import { PERMISSIONS } from "app/permissions"

export const ROUTERLINKS = {
  ROOT: '',
  DASHBOARD: 'dashboard',
  LOGIN: 'login',
  FORGOT_PASSWORD: 'forgot-password',
  CHANGE_PASSWORD: 'change-password',
  TIME: 'time-report/time',
  USER: 'user-directory',
  ADMIN: 'administration',
  REPORT: 'reports',
  RESOURCE_ALLOCATION: 'resource-allocation',
  BOOKING: 'resource-allocation/bookings',
  LINKS: 'links',
  ADDLINKS: 'links/add-links',
  HOLIDAY: 'holiday',
  EXPENSE_REPORT: 'expense-reporting',
  FEED: 'feed',
  ADD_ANNOUNCEMENTS: 'feed/add-feed',
  UPDATE_ANNOUNCEMENTS: 'feed/update-feed',
  VIEW_ANNOUNCEMENT: 'feed/view-feed',
  UPDATE_TRAINING: 'trainings/update-training',
  CALENDAR: 'calendar',
  UPDATE_LINK: 'links/update-link',
  ESAT: 'esat/moodofthecrew',
  ONBOARDINGFORM: 'onboarding-feedback-form',

  //Trainings
  TRAININGS: 'trainings',
  ADDTRAININGS: 'trainings/add-trainings',
  // 'MY_TRAININGS : 'trainings/my-trainings',
  TRAINING_FEEDBACK: 'trainings/training-feedback',
  TRAINING_FEEDBACK_REPORT: 'trainings/feedback-report',
  VIEW_TRAINING: 'trainings/view-training',
  //users
  NEW_USER: 'user-directory/addUser',
  USER_PROFILE: 'user-directory/user',
  UPDATE_PROFILE: 'user-directory/updateProfile',
  UPDATE_MY_PROFILE: 'user-directory/updateMyProfile',

  //Administration
  ACCOUNTS: 'administration/accounts',
  ACCOUNTS_PROJECTS: 'administration/accounts/accounts-projects',
  COUNTRY: 'administration/country',
  DESIG: 'administration/designation',
  ORG: 'administration/organization',
  TASKS: 'administration/tasks',
  TEAMS: 'administration/teams',
  TEMPLATES: 'administration/templates',
  UNITS: 'administration/units',
  NEW_TEMPLATE: 'administration/templates/addTemplate',
  EDIT_TEMPLATE: 'administration/templates/editTemplate',
  NEW_ORGANIZATION: 'administration/organization/addTemplate',
  ADD_ORGANIZATION: 'administration/organization/add',
  EDIT_ORGANIZATION: 'administration/organization/edit',
  ORG_DETAILS: 'administration/organization/view',
  EXPENSE_CATEGORY: 'administration/expense-category',
  FEATURES: 'administration/features',
  EXCLUDE_USERS: 'administration/exclude-users',
  GROUP_USERS: 'administration/group-users',
  NEW_GROUP_USER: 'administration/group-users/addGroupUser',
  EDIT_GROUP_USER: 'administration/group-users/addGroupUser',
  LOCATION: 'administration/location',
  CONFERENCE_ROOMS: 'administration/conference-rooms',
  FEEDBACK_TEMPLATE: 'administration/feedbackTemplate',
  QUESTION_TYPE: 'administration/questionType',
  ADMIN_SKILLS: 'administration/adminSkills',
  AUDIT_TEMPLATE: 'administration/audit',
  AUDIT_ADD_TEMPLATE: 'administration/audit-add-template',
  EDIT_AUDIT_TEMPLATE: 'administration/audit-edit-template',
  SITES: 'administration/sites',
  ORG_ROLES: 'administration/orgRoles',
  ROLE_PERMISSION: 'administration/orgRoles/permission',
  PUBLIC_PROJECTS: 'administration/public-projects',
  INDUSTRIES: 'administration/industries',
  BULK_UPLOAD: 'administration/bulkUpload',
  NEW_BULK_UPLOAD_TEMPLATE: 'administration/bulkUpload/addBulkUploadTemplate',
  EDIT_BULK_UPLOAD_TEMPLATE: 'administration/bulkUpload/editBulkUploadTemplate',
  THOR: 'administration/thor',
  GENERAL_QUERIES: 'administration/thor/general-queries',
  DASHBOARD_BANNER: 'administration/dashboard-banner',
  VENDOR_TYPE: 'administration/vendor-type',
  //Asset-onboarding
  NEW_ASSET: 'administration/asset-management/asset',
  ASSET_MANAGEMENT: 'administration/asset-management',
  ASSET_DETAILS: 'administration/asset-details',
  UPDATE_ASSET: 'administration/asset/update-asset',

  // check-in
  CHECKIN: 'administration/check-in',
  NEW_CHECKIN_TEMPLATE: 'administration/check-in/addTemplate',
  TASK_CHECK_IN: 'projects/task-checkin',

  // csat
  CSAT: 'administration/csat',
  NEW_CSAT_TEMP: 'administration/csat/addTemplate',
  PROJECT_CSAT: 'projects/csat',
  FEEDBACK: 'feedback',

  //reports
  BUSINESS: 'reports/business',
  COUNTRY_REPORT: 'reports/country',
  FOOD_REPORT: 'reports/food',
  FINANCE: 'reports/finance',
  HR: 'reports/hr',
  PUBLIC_PROJECTS_REPORT: 'reports/hr/public-projects-report',
  HR_LEAVE_SUMMARY: 'reports/hr/leave-summary-report',
  PROJECTS: 'reports/project',
  TASK_REPORT: 'reports/task',
  WORK_LOCATION: 'reports/work-location-report',
  USERS: 'reports/users',
  FEATURE: 'reports/feature',
  DEFAULTER_LIST: 'reports/defaulter-list',
  TEAMREPORTS: 'reports/team',
  ACCOUNT: 'reports/account',
  TRAINING: 'reports/training',
  SUBSCRIBE: 'reports/subscribe-report',
  VIEW_SUBSCRIBE: 'reports/subscribe-report/view',
  ACANDO: 'reports/acando-report',
  BILLING: 'reports/finance/billing-report',
  ACCOUNT_BILLING: 'reports/finance/account-billing-report',
  IBU_PROJECT_DETAILS: 'reports/ibu-project-details',
  AWARDS_REPORT: 'reports/awards-report',

  //enhanced reports
  PROJECT_ENHANCED_REPORT: 'enhanced-project-report',
  HR_ENHANCED_REPORT: 'enhanced-hr-report',
  DEPARTMENT_ENHANCED_REPORT: 'enhanced-department-report',
  TEAM_ENHANCED_REPORT: 'enhanced-team-report',
  ACCOUNT_ENHANCED_REPORT: 'enhanced-account-report',
  //bookings
  NEW_BOOKING: 'bookings/add-booking',
  UPDATE_BOOKING: 'bookings/update-booking',
  LOCK: 'administration/lock',
  FINANCE_USER: 'reports/finance/user',

  //Expense report
  ALL_EXPENSE_REPORTS: 'expense-reporting/reports',
  EXPENSE_USER_REPORTS: 'expense-reporting/reports/expense-user-reports',
  EXPENSE_REPORTS_DETAILS: 'details',
  EXPENSE_REPORT_DETAILS: 'expense-reporting/details',
  VIEW_EXPENSE_REPORT: 'expense-reporting/view-report',
  EXP_REPORT: 'view-report',
  EXPENSE_CATEGORY_TEMP: 'administration/expense',

  //tags
  LINK_TAGS: 'links/tags',

  //project collation and sharing
  PROJECT_SHARING: 'adminstration/projectSharing',
  SHARED_PROJECTS: 'projectSharing/sharedProjects',
  PROJECT_GROUPS: 'projectSharing/projectGroups',
  GROUPS_VIEW: 'projectSharing/projectGroups/view',
  GROUP_REPORT: 'projectSharing/projectGroups/reports',

  //Style Guide
  STYLEGUIDE: 'styleguide',
  COLOR: 'styleguide/color',
  ELEMENTS: 'styleguide/elements',
  ICONS: 'styleguide/icons',
  FONTS: 'styleguide/fonts',

  //Organization Onboarding
  ORGANIZATION_ONBOARDING: 'organizationOnboarding',
  ORGANIZATION_USER_REGISTRATION: 'organization-user-registration',
  TIME_EKSTEP: 'time-report/time-ekstep',
  INSIDER: 'insider',
  TIME_DASHBOARD: 'time-dashboard',
  ORG_WISE_PROVISIONING: 'administration/organization/org-wise-provisioning',

  //events
  EVENTS: 'events',
  ADD_EVENT: 'events/add-event',
  UPDATE_EVENT: 'events/update-event',
  EVENT_DETAILS: 'events/view',
  SKILLS: 'user-directory/skills',

  //Projects
  PROJECT: 'projects',
  NEW_PROJECT: 'projects/addProject',
  UPDATE_PROJECT: 'projects/updateProject',
  VIEW_PROJECT: 'projects/viewProject',
  PROJECT_LIST: 'projects/projectList',
  PROJECT_REQUEST: 'projects/requests',
  PROJECT_LESSONS_RISKS: 'projects/projectLessons',
  PROJECT_REPORT: 'projects/report',
  PROJECT_ADMIN: 'projects/admin',
  PROJECT_AUDIT: 'projects/audit',

  // 'VIEW_PROJECT_REQUEST : 'projects/requests',

  //policies
  POLICY: 'policies',
  POLICY_CRUD: 'policies/crud',
  //currency
  CURRENCY: 'administration/currency',

  // //reusable:policy-list
  // 'POLICYLIST_PARENT : 'policylist-parent',
  // Notes
  NOTES: 'notes',

  SALES: 'utilization/sales',
  UTILIZATION: 'utilization',
  RESOURCE: 'utilization/resource',
  ALLOCATION_PLANNER: 'utilization/allocation-planner',
  PMS: 'pms',
  MENTOR_VIEW: 'pms/mentor-view',
  MENTEE_VIEW: 'pms/mentor-view/mentee-details',
  PROFILE: 'profile',
  COMPLIANCE: 'profile/compliance',
  USER_ALLOCATION: 'profile/allocation',
  INSIGHTS: 'insights',

  // Awards
  AWARDS: 'awards',
  AWARD_LEADERBOARD: 'awards/leaderboard',

  // Allocation
  AVAILABILITY: 'availability',

  // Skills
  SKILL: 'skills',

  //Forecast
  FORECAST: 'forecast',

  //Nearby
  NEARBY: 'nearby',

  //IbuSpace
  IBUSPACE: 'ibu-space',

  //CustomerSpace
  CUSTOMERSPACE: 'portfolio',
  CUSTOMER: 'portfolio/customer',
  ADD_CUSTOMER: 'portfolio/add-customer',
  UPDATE_CUSTOMER: 'portfolio/update-customer',

  // Chatbot
  CHATBOT: 'chat-bot',

  //FoodRegister
  FOODREGISTER: 'food-register',

  //Finance Module
  FINANCE_MODULE: 'finance-module',
  PROJECT_DETAILS: 'finance-module/project-details',

  //FEEDS
  FEEDS: 'feeds',

  //know-kronos
  KNOWKRONOS: 'know-kronos',

  //Vendors
  VENDORS: 'vendors',
  VENDOR: 'vendors/vendor',
  ADD_VENDOR: 'vendors/add-vendor',
  UPDATE_VENDOR: 'vendors/update-vendor',

  //Industries
  INDUSTRIES_MODULE: 'industries',
  INDUSTRY_PROFILE: 'industries/industry-profile',

  //Solutions
  SOLUTIONS_MODULE: 'solutions',
  SOLUTION_PROFILE: 'solutions/solution-profile',

  //micro-learning
  // MICROLEARNING: 'micro-learning',

}

export const ROUTERLINKS_VISIBILITY = {
  ROOT: false,
  DASHBOARD: true,
  LOGIN: false,
  FORGOT_PASSWORD: false,
  CHANGE_PASSWORD: false,

  TIME: true,
  USER: true,
  ADMIN: true,
  REPORT: true,

  RESOURCE_ALLOCATION: false,
  BOOKING: false, // resource booking

  LINKS: true,
  ADDLINKS: false,
  HOLIDAY: true,
  EXPENSE_REPORT: true,

  FEED: false,
  ADD_ANNOUNCEMENTS: false,
  UPDATE_ANNOUNCEMENTS: false,
  VIEW_ANNOUNCEMENT: false,

  UPDATE_TRAINING: false,
  CALENDAR: true,
  UPDATE_LINK: false,
  ESAT: false,
  ONBOARDINGFORM: false,

  //Trainings
  TRAININGS: true,
  ADDTRAININGS: { moduleId: PERMISSIONS.MODULES.TRAININGS, actionId: PERMISSIONS.ACTIONS.MODIFY_TRAINING },
  // 'MY_TRAININGS : 'trainings/my-trainings', //not there
  TRAINING_FEEDBACK: false,
  TRAINING_FEEDBACK_REPORT: false,
  VIEW_TRAINING: false,

  //users
  NEW_USER: { moduleId: PERMISSIONS.MODULES.USER_DIRECTORY, actionId: PERMISSIONS.ACTIONS.MODIFY_USER_DIRECTORY },
  USER_PROFILE: false,
  UPDATE_PROFILE: false,
  UPDATE_MY_PROFILE: false,

  //Administration
  ACCOUNTS: { moduleId: PERMISSIONS.MODULES.ADMINISTRATION, actionId: PERMISSIONS.ACTIONS.MODIFY_ACCOUNT },
  ACCOUNTS_PROJECTS: false,
  COUNTRY: true,
  DESIG: { moduleId: PERMISSIONS.MODULES.ADMINISTRATION, actionId: PERMISSIONS.ACTIONS.MODIFY_DESIGNATIONS },
  ORG: { moduleId: PERMISSIONS.MODULES.ADMINISTRATION, actionId: PERMISSIONS.ACTIONS.MODIFY_ALL_ORG },
  TASKS: false,
  TEAMS: { moduleId: PERMISSIONS.MODULES.ADMINISTRATION, actionId: PERMISSIONS.ACTIONS.MODIFY_TEAM },
  TEMPLATES: { moduleId: PERMISSIONS.MODULES.ADMINISTRATION, actionId: PERMISSIONS.ACTIONS.MODIFY_TASK_TEMPLATE },
  UNITS: { moduleId: PERMISSIONS.MODULES.ADMINISTRATION, actionId: PERMISSIONS.ACTIONS.MODIFY_BUSINESS_UNIT },
  NEW_TEMPLATE: { moduleId: PERMISSIONS.MODULES.ADMINISTRATION, actionId: PERMISSIONS.ACTIONS.MODIFY_TASK_TEMPLATE },
  EDIT_TEMPLATE: false,
  // { moduleId: PERMISSIONS.MODULES.ADMINISTRATION, actionId: PERMISSIONS.ACTIONS.MODIFY_TASK_TEMPLATE },
  NEW_ORGANIZATION: false,
  ADD_ORGANIZATION: { moduleId: PERMISSIONS.MODULES.ADMINISTRATION, actionId: PERMISSIONS.ACTIONS.MODIFY_ALL_ORG },
  EDIT_ORGANIZATION: false, //recheck
  ORG_DETAILS: false,
  EXPENSE_CATEGORY: false, //recheck
  FEATURES: { moduleId: PERMISSIONS.MODULES.ADMINISTRATION, actionId: PERMISSIONS.ACTIONS.MODIFY_FEATURE },
  EXCLUDE_USERS: { moduleId: PERMISSIONS.MODULES.ADMINISTRATION, actionId: PERMISSIONS.ACTIONS.EXCLUDE_USER },
  GROUP_USERS: { moduleId: PERMISSIONS.MODULES.ADMINISTRATION, actionId: PERMISSIONS.ACTIONS.MODIFY_USER_GROUPS },
  NEW_GROUP_USER: false,
  EDIT_GROUP_USER: false,
  LOCATION: { moduleId: PERMISSIONS.MODULES.ADMINISTRATION, actioonId: PERMISSIONS.ACTIONS.MODIFY_LOCATIONS },
  CONFERENCE_ROOMS: false,
  FEEDBACK_TEMPLATE: false,
  QUESTION_TYPE: false,
  ADMIN_SKILLS: { moduleId: PERMISSIONS.MODULES.ADMINISTRATION, actionId: PERMISSIONS.ACTIONS.MODIFY_SKILLS },
  AUDIT_TEMPLATE: { moduleId: PERMISSIONS.MODULES.ADMINISTRATION, actionId: PERMISSIONS.ACTIONS.VIEW_AUDIT },
  AUDIT_ADD_TEMPLATE: { moduleId: PERMISSIONS.MODULES.ADMINISTRATION, actionId: PERMISSIONS.ACTIONS.MODIFY_AUDIT },
  EDIT_AUDIT_TEMPLATE: { moduleId: PERMISSIONS.MODULES.ADMINISTRATION, actionId: PERMISSIONS.ACTIONS.MODIFY_AUDIT },
  SITES: { moduleId: PERMISSIONS.MODULES.ADMINISTRATION, actionId: PERMISSIONS.ACTIONS.MODIFY_SITES },
  ORG_ROLES: { moduleId: PERMISSIONS.MODULES.ADMINISTRATION, actionId: PERMISSIONS.ACTIONS.VIEW_ROLE_PERMISSIONS },
  ROLE_PERMISSION: false,
  PUBLIC_PROJECTS: { moduleId: PERMISSIONS.MODULES.ADMINISTRATION, actionId: PERMISSIONS.ACTIONS.MODIFY_PUBLIC_PROJECT },
  INDUSTRIES: { moduleId: PERMISSIONS.MODULES.ADMINISTRATION, actionId: PERMISSIONS.ACTIONS.MODIFY_DOMAIN },
  BULK_UPLOAD: true,
  NEW_BULK_UPLOAD_TEMPLATE: true,
  EDIT_BULK_UPLOAD_TEMPLATE: false,
  THOR: true,
  GENERAL_QUERIES: { moduleId: PERMISSIONS.MODULES.ADMINISTRATION, actionId: PERMISSIONS.ACTIONS.MODIFY_CURRENCY },
  DASHBOARD_BANNER: { moduleId: PERMISSIONS.MODULES.ADMINISTRATION, actionId: PERMISSIONS.ACTIONS.MODIFY_DASHBOARD_BANNER },
  VENDOR_TYPE: { moduleId: PERMISSIONS.MODULES.ADMINISTRATION, actionId: PERMISSIONS.ACTIONS.MODIFY_VENDOR_TYPE },
  //Asset-onboarding
  NEW_ASSET: { moduleId: PERMISSIONS.MODULES.ADMINISTRATION, actionId: PERMISSIONS.ACTIONS.MODIFY_ASSET },
  ASSET_MANAGEMENT: { moduleId: PERMISSIONS.MODULES.ADMINISTRATION, actionId: PERMISSIONS.ACTIONS.VIEW_ASSET },
  ASSET_DETAILS: false,
  UPDATE_ASSET: false,

  // check-in
  CHECKIN: { moduleId: PERMISSIONS.MODULES.ADMINISTRATION, actionId: PERMISSIONS.ACTIONS.MODIFY_CHECKIN_TEMPLATE },
  NEW_CHECKIN_TEMPLATE: { moduleId: PERMISSIONS.MODULES.ADMINISTRATION, actionId: PERMISSIONS.ACTIONS.MODIFY_CHECKIN_TEMPLATE },
  TASK_CHECK_IN: false,

  // csat
  CSAT: { moduleId: PERMISSIONS.MODULES.ADMINISTRATION, actionId: PERMISSIONS.ACTIONS.MODIFY_CSAT_TEMPLATE },
  NEW_CSAT_TEMP: { moduleId: PERMISSIONS.MODULES.ADMINISTRATION, actionId: PERMISSIONS.ACTIONS.MODIFY_CSAT_TEMPLATE },
  PROJECT_CSAT: false,
  FEEDBACK: false,

  //reports
  BUSINESS: { moduleId: PERMISSIONS.MODULES.TIMEREPORT, actionId: PERMISSIONS.ACTIONS.VIEW_BUSINESS_UNIT_REPORT },
  COUNTRY_REPORT: { moduleId: PERMISSIONS.MODULES.TIMEREPORT, actionId: PERMISSIONS.ACTIONS.VIEW_COUNTRY_REPORT },
  FOOD_REPORT: { moduleId: PERMISSIONS.MODULES.TIMEREPORT, actionId: PERMISSIONS.ACTIONS.VIEW_FOOD_REPORT },
  FINANCE: { moduleId: PERMISSIONS.MODULES.TIMEREPORT, actionId: PERMISSIONS.ACTIONS.VIEW_PROJECT_INVOICE_REPORT },
  HR: { moduleId: PERMISSIONS.MODULES.TIMEREPORT, actionId: PERMISSIONS.ACTIONS.VIEW_LEAVE_GENERAL_REPORT },
  PUBLIC_PROJECTS_REPORT: { moduleId: PERMISSIONS.MODULES.TIMEREPORT, actionId: PERMISSIONS.ACTIONS.VIEW_LEAVE_GENERAL_REPORT },
  HR_LEAVE_SUMMARY: { moduleId: PERMISSIONS.MODULES.TIMEREPORT, actionId: PERMISSIONS.ACTIONS.VIEW_LEAVE_GENERAL_REPORT },
  PROJECTS: { moduleId: PERMISSIONS.MODULES.TIMEREPORT, actionId: PERMISSIONS.ACTIONS.VIEW_PROJECT_INVOICE_REPORT },
  TASK_REPORT: { moduleId: PERMISSIONS.MODULES.TIMEREPORT, actionId: PERMISSIONS.ACTIONS.VIEW_TASK_REPORT },
  WORK_LOCATION: { moduleId: PERMISSIONS.MODULES.TIMEREPORT, actionId: PERMISSIONS.ACTIONS.VIEW_WORK_LOCATION_REPORT },
  USERS: { moduleId: PERMISSIONS.MODULES.TIMEREPORT, actionId: PERMISSIONS.ACTIONS.VIEW_PERSONAL_REPORT },
  FEATURE: { moduleId: PERMISSIONS.MODULES.TIMEREPORT, actionId: PERMISSIONS.ACTIONS.VIEW_FEATURE_INVOICE_REPORT },
  DEFAULTER_LIST: { moduleId: PERMISSIONS.MODULES.TIMEREPORT, actionId: PERMISSIONS.ACTIONS.VIEW_DEFAULTERS_REPORT },
  TEAMREPORTS: { moduleId: PERMISSIONS.MODULES.TIMEREPORT, actionId: PERMISSIONS.ACTIONS.VIEW_TEAM_REPORT },
  ACCOUNT: { moduleId: PERMISSIONS.MODULES.TIMEREPORT, actionId: PERMISSIONS.ACTIONS.VIEW_ACCOUNT_REPORT },
  TRAINING: { moduleId: PERMISSIONS.MODULES.TIMEREPORT, actionId: PERMISSIONS.ACTIONS.VIEW_TRAINING_REPORT },
  SUBSCRIBE: { moduleId: PERMISSIONS.MODULES.TIMEREPORT, actionId: PERMISSIONS.ACTIONS.MODIFY_USER_REPORT_SUBSCRIPTION },
  VIEW_SUBSCRIBE: false,
  ACANDO: { moduleId: PERMISSIONS.MODULES.TIMEREPORT, actionId: PERMISSIONS.ACTIONS.VIEW_ACANDO_REPORT },
  BILLING: { moduleId: PERMISSIONS.MODULES.TIMEREPORT, actionId: PERMISSIONS.ACTIONS.VIEW_PROJECT_INVOICE_REPORT },
  ACCOUNT_BILLING: { moduleId: PERMISSIONS.MODULES.TIMEREPORT, actionId: PERMISSIONS.ACTIONS.VIEW_PROJECT_INVOICE_REPORT },
  IBU_PROJECT_DETAILS: { moduleId: PERMISSIONS.MODULES.TIMEREPORT, actionId: PERMISSIONS.ACTIONS.VIEW_PROJECT_INVOICE_REPORT },
  AWARDS_REPORT: { moduleId: PERMISSIONS.MODULES.TIMEREPORT, actionId: PERMISSIONS.ACTIONS.VIEW_FOOD_REPORT },

  //enhanced reports
  PROJECT_ENHANCED_REPORT: false,
  HR_ENHANCED_REPORT: false,
  DEPARTMENT_ENHANCED_REPORT: false,
  TEAM_ENHANCED_REPORT: false,
  ACCOUNT_ENHANCED_REPORT: false,
  //bookings
  NEW_BOOKING: false,
  UPDATE_BOOKING: false,
  LOCK: { moduleId: PERMISSIONS.MODULES.ADMINISTRATION, actionId: PERMISSIONS.ACTIONS.MODIFY_LOCK_SYSTEM },
  FINANCE_USER: { moduleId: PERMISSIONS.MODULES.TIMEREPORT, actionId: PERMISSIONS.ACTIONS.VIEW_MONTH_INVOICE_REPORT },

  //Ask Misha
  //Expense report
  ALL_EXPENSE_REPORTS: false, //recheck
  // 'expense-reporting/reports',
  EXPENSE_USER_REPORTS: false, //recheck
  // 'expense-reporting/reports/expense-user-reports',
  EXPENSE_REPORTS_DETAILS: false,
  EXPENSE_REPORT_DETAILS: false,
  VIEW_EXPENSE_REPORT: { moduleId: PERMISSIONS.MODULES.EXPENSES, actionId: PERMISSIONS.ACTIONS.VIEW_ORG_EXPENSE },
  EXP_REPORT: false,
  EXPENSE_CATEGORY_TEMP: { moduleId: PERMISSIONS.MODULES.ADMINISTRATION, actionId: PERMISSIONS.ACTIONS.MODIFY_EXPENSE_CATEGORY },

  //tags
  LINK_TAGS: false,

  //project collation and sharing
  PROJECT_SHARING: false,
  SHARED_PROJECTS: false,
  PROJECT_GROUPS: false,
  GROUPS_VIEW: false,
  GROUP_REPORT: false,

  //Style Guide
  STYLEGUIDE: false,
  COLOR: false,
  ELEMENTS: false,
  ICONS: false,
  FONTS: false,

  //Organization Onboarding
  ORGANIZATION_ONBOARDING: false,
  ORGANIZATION_USER_REGISTRATION: false,
  TIME_EKSTEP: false,
  INSIDER: { moduleId: PERMISSIONS.MODULES.TIMEREPORT, actionId: PERMISSIONS.ACTIONS.VIEW_PERSONAL_REPORT },
  TIME_DASHBOARD: false,
  ORG_WISE_PROVISIONING: false,

  //events
  EVENTS: { moduleId: PERMISSIONS.MODULES.EVENTS, actionId: PERMISSIONS.ACTIONS.VIEW_EVENT },
  ADD_EVENT: { moduleId: PERMISSIONS.MODULES.EVENTS, actionId: PERMISSIONS.ACTIONS.MODIFY_EVENT },
  UPDATE_EVENT: false,
  EVENT_DETAILS: false,
  SKILLS: false,

  //Projects
  PROJECT: true,
  NEW_PROJECT: { moduleId: PERMISSIONS.MODULES.PROJECTS, actionId: PERMISSIONS.ACTIONS.MODIFY_PROJECT },
  UPDATE_PROJECT: false,
  VIEW_PROJECT: false,
  PROJECT_LIST: false, //recheck
  PROJECT_REQUEST: { moduleId: PERMISSIONS.MODULES.PROJECTS, actionId: PERMISSIONS.ACTIONS.MODIFY_PROJECT_REQUEST_STATUS },
  PROJECT_LESSONS_RISKS: false,
  PROJECT_REPORT: false,
  PROJECT_ADMIN: false,
  PROJECT_AUDIT: false,

  // 'VIEW_PROJECT_REQUEST : 'projects/requests',

  //policies
  POLICY: true, //recheck
  // this.utils.ROLEPERMISSION.MODULES.POLICIES, this.utils.ROLEPERMISSION.ACTIONS.VIEW_POLICY
  POLICY_CRUD: false, //check
  //currency
  CURRENCY: { moduleId: PERMISSIONS.MODULES.ADMINISTRATION, actionId: PERMISSIONS.ACTIONS.MODIFY_CURRENCY },

  // //reusable:policy-list
  // 'POLICYLIST_PARENT : 'policylist-parent',
  // Notes
  NOTES: false, //does exist in projects notes but needs id

  SALES: false,
  UTILIZATION: false,
  RESOURCE: false,
  ALLOCATION_PLANNER: false,
  PMS: false,
  MENTOR_VIEW: false,
  MENTEE_VIEW: false,
  PROFILE: true,
  COMPLIANCE: false,
  USER_ALLOCATION: false,
  INSIGHTS: false,

  // Awards
  AWARDS: true,
  AWARD_LEADERBOARD: { moduleId: PERMISSIONS.MODULES.AWARDS, actionId: PERMISSIONS.ACTIONS.VIEW_AWARD_LEADERBOARD },

  // Allocation
  AVAILABILITY: true,

  // Skills
  SKILL: true,

  //Forecast
  FORECAST: false,

  //Nearby
  NEARBY: false,

  //IbuSpace
  IBUSPACE: true,

  //CustomerSpace
  CUSTOMERSPACE: true,
  CUSTOMER: false,
  ADD_CUSTOMER: { moduleId: PERMISSIONS.MODULES.CUSTOMERSPACE, actionId: PERMISSIONS.ACTIONS.MODIFY_CUSTOMER_SPACE },
  UPDATE_CUSTOMER: false,

  // Chatbot
  CHATBOT: true,

  //FoodRegister
  FOODREGISTER: true,

  //Finance Module
  FINANCE_MODULE: true,
  PROJECT_DETAILS: { moduleId: PERMISSIONS.MODULES.TIMEREPORT, actionId: PERMISSIONS.ACTIONS.VIEW_PROJECT_INVOICE_REPORT },


  //FEEDS
  FEEDS: true,

  //MICROLEARNING
  // MICROLEARNING: true,
  //know-kronos
  KNOWKRONOS: true,

  //Industries
  INDUSTRIES_MODULE: true,
  INDUSTRY_PROFILE: false,

  //Solutions
  SOLUTIONS_MODULE: true,
  SOLUTION_PROFILE: false,

  //Vendors
  VENDORS: true,
  VENDOR: false,
  ADD_VENDOR: { moduleId: PERMISSIONS.MODULES.VENDORS, actionId: PERMISSIONS.ACTIONS.MODIFY_VENDORS },
  UPDATE_VENDOR: false,
}
